import usePost from "../../services/apiPostService";
import infoButton from "../../assets/Images/info-icon.svg";
import cancelIcon from "../../assets/Images/cross-icon.svg";
import figma from "../../assets/Images/figma-icon.svg";
import "./UploadFileStyles.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { routeConfig } from "../../constants/routeConfig";
import { Alert, LinearProgress, Snackbar } from "@mui/material";
import { guideLines, behindTheScenesContent } from "../../constants/constants";
import CustomModal from "../../components/Modal/model";

const UploadScreen: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [snackbar, setSnackbar] = useState<{
    message: string;
    severity: "success" | "error" | "info";
    open: boolean;
  }>({
    message: "",
    severity: "info",
    open: false,
  });
  const [isContentVisible, setIsContentVisible] = useState(false);
  const { doPost } = usePost();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (selectedFile) {
      try {
        const formData = new FormData();
        formData.append("file", selectedFile);
        setIsUploading(true);
        try {
          const response = await doPost(
            "aura/upload/figma",
            formData,
            "multipart/form-data",
          );
          if (response) {
            setSelectedFile(null);
            if (response.data.status === "Success") {
              navigate("/thankyou");
            } else {
              setUploadedFileName(null);
              setSelectedFile(null);
            }
          }
        } catch (error) {
          console.error(error);
          if (sessionStorage.getItem("acess_token") === null) {
            setSnackbar({
              message: "Please Login again...",
              severity: "error",
              open: true,
            });
          } else {
            setSnackbar({
              message: "Error uploading file",
              severity: "error",
              open: true,
            });
          }
        } finally {
          setIsUploading(false);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      console.error("No file selected");
    }
  };
  const [isUploading, setIsUploading] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      if (file) {
        setIsUploading(true);
        setUploadedFileName(file.name);
        setSelectedFile(file);
        setTimeout(() => {
          if (file.name.endsWith(".fig")) {
            setIsUploading(false);
          } else {
            setIsUploading(false);
          }
        }, 2000);
      }
    }
  };
  const handleSnackbarClose = () => {
    setSnackbar((prevState) => ({ ...prevState, open: false }));
  };

  const toggleContentVisibility = () => {
    setIsContentVisible((prev) => !prev);
  };

  return (
    <>
      <div>
        <div className="details-upload-container">
          <div className="upload-content">
            <p className="upload-content-heading">Upload Your Figma File.</p>
            <p className="upload-content-description">
              Please ensure your file meets the guidelines before uploading
            </p>
          </div>
          <div className="info-cancel-container">
            <div className="info-container">
              <img src={infoButton} alt="info" className="info-icon" />
              <p className="hover-content">
                Divami reserves the right to accept or reject any submissions
                based on our own internal criteria of suitability and
                evaluation.
              </p>
            </div>
            <div className="cancel-container">
              <img
                src={cancelIcon}
                alt="cancel"
                className="cancel-icon"
                onClick={() => {
                  navigate(routeConfig.userDashboard);
                }}
              />
            </div>
          </div>
        </div>
        <div className="upload-container">
          <div className="figma-upload-container">
            {isUploading ? (
              <>
                <p className="upload-pending">Uploading...</p>
                {/* <img
                  className="figma-img-pending"
                  src={uploadPending}
                  alt="figma loading"
                /> */}
                <LinearProgress
                  sx={{
                    ".MuiLinearProgress-bar": {
                      backgroundColor: "#fb0000 !important",
                      height: "2px !important",
                    },
                  }}
                  className="linear-progress-bar"
                />
              </>
            ) : uploadedFileName ? (
              <>
                <img className="figma-img" src={figma} alt="figma" />
                <p className="figma-reupload-description">{uploadedFileName}</p>
                <input
                  type="file"
                  className="input-file"
                  accept=".fig"
                  placeholder="Select File"
                  hidden
                  id="upload-file"
                  onChange={handleFileChange}
                />
                <label
                  htmlFor="upload-file"
                  className="upload-button"
                  style={{ width: "159px" }}
                >
                  Re-upload File
                </label>
                <button className="generate-design" onClick={handleSubmit}>
                  Generate Code from Designs
                </button>
              </>
            ) : (
              <>
                <img className="figma-img" src={figma} alt="figma" />
                <p className="figma-description">
                  Select a figma file or drag and drop here
                </p>
                <input
                  type="file"
                  className="input-file"
                  accept=".fig"
                  placeholder="Select File"
                  hidden
                  id="upload-file"
                  onChange={handleFileChange}
                />
                <label htmlFor="upload-file" className="upload-button">
                  Select file
                </label>
              </>
            )}
          </div>

          <div className="discover-container">
            <div className="guidelines-container">
              <div className={`guidelines-heading`}>
                <p className="guidelines-text">GuideLines</p>
                <ol className="figma-ordered-list">
                  {guideLines.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ol>
              </div>
              {isContentVisible && (
                <CustomModal
                  open={isContentVisible}
                  title="Behind The Scenes"
                  onClose={toggleContentVisibility}
                  onConfirm={toggleContentVisibility}
                  confirmText="Close"
                  actionConfirmButtonClassName="confirmButtonStyle"
                >
                  {behindTheScenesContent}
                </CustomModal>
              )}
              <p className="discover-text" onClick={toggleContentVisibility}>
                [ Discover What Happens Behind the Scenes ]
              </p>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbar.open}
        sx={{ marginBotton: "100px" }}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default UploadScreen;
