import { useState } from "react";
import Axios, { AxiosResponse, AxiosError } from "axios";
import { AURA_BASE } from "../constants";

interface RequestInformationTypes {
  name?: string;
}

const usePut = () => {
  const [totaldata, setTotalData] = useState<AxiosResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError | null | unknown>(null);
  const [requestInformation, setRequestInformation] = useState<
    RequestInformationTypes | undefined
  >({});

  const doPut = async (
    url: string,
    body: object,
    requestInformation?: RequestInformationTypes,
  ) => {
    setLoading(true);
    setRequestInformation(requestInformation);

    try {
      const token = sessionStorage.getItem("access_token");
      const Url= `${AURA_BASE}/${url}`;
      const response = await Axios.put(Url, body, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setTotalData(response);
    } catch (error: unknown) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { totaldata, loading, error, doPut, requestInformation };
};

export default usePut;
