import React, { useState } from "react";
import { roleCard } from "../../constants/constants";
import "../../constants/messages";
import "./roleCardStyles.css";
import { messages } from "../../constants/messages";
interface iRoleProps {
  handleCardClick: (id: number) => void;
}
const RoleCard = ({ handleCardClick }: iRoleProps) => {
  const [hoveredId, setHoveredId] = useState<number | null>(null);

  const handleMouseEnter = (id: number) => {
    setHoveredId(id);
  };
  const handleMouseLeave = () => {
    setHoveredId(null);
  };
  return (
    <div className="cardContainer">
      <div className="roleCardWrapper">
        {roleCard.slice(0, 4).map((role) => {
          return (
            <div className="cardWrapper" key={role.id} onMouseEnter={
              role.id < 3 ? () => handleMouseEnter(role.id) : undefined
            }
            onMouseLeave={role.id < 3 ? handleMouseLeave : undefined}>
              <div
                key={role.id}
                className={`roleCardContainer ${role.id < 3 ? "hoverStyle" : ""}`}
                onClick={() => handleCardClick(role.id)}
                
              >
                <div className="rolesCard">
                  <div className="imageContainer">
                    <img
                      className="assistantImage"
                      src={
                        hoveredId === role.id
                          ? role.hoverImage
                          : role.activeImage
                      }
                      alt="role"
                    />
                    {role.id > 2 && (
                      <div className="comingSoonWrapper">
                        <div className="comingSoonText">
                          {messages.COMINGSOON}
                        </div>
                      </div>
                    )}
                  </div>
                  <p className={`roleText`}>{role.text}</p>
                </div>
              </div>
              {/* {role.id < 3 && hoveredId === role.id && (
                <div className="knowMoreText">[ Know More ]</div>
              )} */}
            </div>
          );
        })}
      </div>

      <div className="rolesWrapper">
        {roleCard.slice(4, 8).map((role) => {
          return (
            <div
              key={role.id}
              className="roleCardContainer"
              onClick={() => handleCardClick(role.id)}
            >
              <div className="rolesCard">
                <div className="imageContainer">
                  <img src={role.activeImage} alt="role" />
                  {role.id > 2 && (
                    <div className="comingSoonWrapper">
                      <div className="comingSoonText">
                        {messages.COMINGSOON}
                      </div>
                    </div>
                  )}
                </div>
                <p className="roleText">{role.text}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default RoleCard;
