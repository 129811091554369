import React from "react";
export interface iButtonProps {
  onClick: () => void;
  children: React.ReactNode | string;
  className?: string;
}

export const Button: React.FC<iButtonProps> = ({
  onClick,
  children,
  className,
}) => {
  return (
    <button className={`${className} button`} onClick={onClick}>
      {children}
    </button>
  );
};
