import { useState } from "react";
import Axios, { AxiosResponse, AxiosError } from "axios";
import { AURA_BASE } from "../constants";
interface RequestInformationTypes {
  name?: string;
}

const useFetch = () => {
  const [data, setData] = useState<AxiosResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError | null | unknown>(null);
  const [requestInformation, setRequestInformation] = useState<
    Partial<RequestInformationTypes> | undefined
  >(undefined);

  const doFetch = async (
    url: string,
    options?: object,
    requestInformation?: Partial<RequestInformationTypes>,
  ) => {
    setLoading(true);
    setRequestInformation(requestInformation);
    return await fetchData(url, options);
  };

  const fetchData = async (url: string, options?: object) => {
    try {
      const token = sessionStorage.getItem("access_token");
      const response = await Axios({
        url: `${AURA_BASE}/${url}`,
        ...options,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setData(response);
      return response;
    } catch (error: unknown) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, doFetch, requestInformation };
};

export default useFetch;
