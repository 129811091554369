import React, { useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import TextField from "../../common/Input/inputField";
import { Button, Snackbar, Alert } from "@mui/material";
import { bannerFormFieldsTypes } from "./types";
import "./registerPageStyles.css";
import { messages } from "../../constants/messages";
import arrow from "../../assets/Images/arrow.svg";
import useFetch from "../../services/apiService";
import { useNavigate } from "react-router-dom";
import { routeConfig } from "../../constants/routeConfig";

const RegisterPage: React.FC = () => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const schema = Yup.object().shape({
    first_name: Yup.string().required("Full Name is required"),
    email: Yup.string()
      .required("Email is required")
      .matches(emailRegex, "Invalid email"),
    company: Yup.string().required("Company is required"),
    message: Yup.string(),
  });
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors, isSubmitted },
  } = useForm<bannerFormFieldsTypes>({
    resolver: yupResolver(schema),
  });

  const onChangeFields = React.useCallback(
    (name: "first_name" | "email" | "company" | "message") =>
      async (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(name, event.target.value);
        await trigger(name);
      },
    [setValue, isSubmitted, trigger],
  );

  const [showToast, setShowToast] = React.useState<boolean>(false);
  const [errorToast, setErrorToast] = React.useState<string | null>(null);
  const [successMessage, setSuccessMessage] = React.useState<string>("");
  const [registerLoader, setRegisterLoader] = React.useState<boolean>(false);

  const isFormValid =
    !errors.email &&
    !errors.first_name &&
    !errors.company &&
    watch("email") &&
    watch("first_name") &&
    watch("company");

  const { doFetch, error } = useFetch();

  const onSubmit: SubmitHandler<bannerFormFieldsTypes> = async (data) => {
    setRegisterLoader(true);
    try {
      const payload = {
        first_name: data.first_name,
        email: data.email,
        company: data.company,
        message: data.message || "",
      };

      const response = await doFetch("aura/registration/register", {
        method: "POST",
        data: payload,
      });
      if (response?.status === 201) {
        setShowToast(true);
        setErrorToast(null);
        setSuccessMessage(response?.data?.message);
        setValue("first_name", "");
        setValue("email", "");
        setValue("company", "");
        setValue("message", "");
        setRegisterLoader(false);
        setTimeout(() => {
          navigate(routeConfig.login);
        }, 10000);
      } else {
        setShowToast(false);
        setRegisterLoader(false);
        console.error("Form submission failed:", error);
      }
    } catch (errors) {
      setRegisterLoader(false);
      console.error("Form submission failed:", error);
    }
  };

  useEffect(() => {
    if (error) {
      setErrorToast(error?.response?.data?.message || "Something went wrong");
    }
  }, [error]);

  console.log("errors", error);

  return (
    <div className="formBackground">
      <div className="formContainer">
        <div className="formPageWrapper">
          <div className="formTextRole">{messages.GETREGISTERED}</div>
          <div className="formtextRoleDesc">{messages.INSTRUCTION}</div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="registerWrapper">
              <div className="firstNameWrapper">
                <TextField
                  type="text"
                  placeholder="Enter your full name"
                  {...register("first_name")}
                  onChange={onChangeFields("first_name")}
                  value={watch("first_name")}
                  errorMessage={errors.first_name?.message}
                />
                {errors.first_name && (
                  <p className="errorText">{errors.first_name.message}</p>
                )}
              </div>
              <div className="firstNameWrapper">
                <TextField
                  type="text"
                  placeholder="Enter your work email"
                  {...register("email")}
                  onChange={onChangeFields("email")}
                  value={watch("email")}
                  errorMessage={errors.email?.message}
                />
                {errors.email && (
                  <p className="errorText">{errors.email.message}</p>
                )}
              </div>
              <div className="firstNameWrapper">
                <TextField
                  placeholder="Enter your company name"
                  {...register("company")}
                  onChange={onChangeFields("company")}
                  value={watch("company")}
                  errorMessage={errors.company?.message}
                />
                {errors.company && (
                  <p className="errorText">{errors.company.message}</p>
                )}
              </div>
              <div className="textAreaWrapper">
                <TextField
                  placeholder="Enter your message"
                  {...register("message")}
                  multiline
                  onChange={onChangeFields("message")}
                  value={watch("message")}
                />
              </div>
            </div>
            <Button
              className="loginButton"
              type="submit"
              disabled={!isFormValid || registerLoader}
              style={{
                width: "131px",
                textTransform: "none",
                height: "56px",
                borderRadius: "999px",
                fontSize: "16px",
                fontWeight: "500",
                fontFamily: "TenorSans",
                textAlign: "center",
                cursor: !(!isFormValid || registerLoader)
                  ? "pointer"
                  : "not-allowed",
                backgroundColor: !(!isFormValid || registerLoader)
                  ? "red"
                  : "#E0E3E6",
                color: !(!isFormValid || registerLoader) ? "#fff" : "#fff",
                border: "none",
                transition: "background-color 0.3s ease",
              }}
            >
              {registerLoader ? messages.REGISTERING : messages.SUBMIT}
              <img
                src={arrow}
                alt="arrow"
                className="arrow"
                style={{ marginLeft: "4px", height: "16px", width: "16px" }}
              />
            </Button>
          </form>
          <div className="contactSupport">
            {messages.EXISTINGUSER}{" "}
            <a href={routeConfig.login}>{messages.LOGINHERE}</a>
          </div>
          <Snackbar
            open={showToast}
            // autoHideDuration={6000}
            onClose={() => setShowToast(false)}
            className="snackbarAlert"
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert
              onClose={() => setShowToast(false)}
              severity="success"
              variant="filled"
              className="snackbarSuccess"
            >
              {successMessage}
            </Alert>
          </Snackbar>
          <Snackbar
            open={Boolean(errorToast)}
            onClose={() => setErrorToast(null)}
            className="snackbarAlert"
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Alert
              onClose={() => setErrorToast(null)}
              severity="error"
              variant="filled"
              className="snackbarError"
            >
              {errorToast}
            </Alert>
          </Snackbar>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
