import { Suspense, useEffect, useState } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import QuestionsComponent from "./components/QuestionsComponent/Questions";
import PoSummary from "./components/PoSummary/poSummary";
import UserSubmissionAnalysis from "./components/UserSubmissionAnalysis/userSubmissionAnalysis";

import BasicTabs from "./components/TabsCompoenent/tabsComponent";
import UploadScreen from "./pages/UploadFile/UploadFile";
import RegisterPage from "./pages/RegisterPage/registerPage";
import Login from "./pages/Login/login";
import { routeConfig } from "./constants/routeConfig";
import UserDashboard from "./pages/UserDashboard/userDashboard";
import Header from "./components/Header/header";
import ProtectedPage from "./utils/ProtectedPage";
import MobileLayout from "./components/MobileLayout/MobileLayout";

export default function App() {
  const [answeredQuestions, setAnsweredQuestions] = useState<{
    [key: string]: { question: string; answer: string; message: string };
  }>({});
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 1023);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1023);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Router>
      <div className="App">
        <Header />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            {isMobile ? (
              <Route path="*" element={<MobileLayout />} />
            ) : (
              <>
                <Route
                  path={routeConfig.base}
                  element={<Navigate to={routeConfig.login} />}
                />
                <Route
                  path={routeConfig.questions}
                  element={
                    <ProtectedPage
                      roles={["admin", "user"]}
                      element={QuestionsComponent}
                      answeredQuestions={answeredQuestions}
                      setAnsweredQuestions={setAnsweredQuestions}
                    />
                  }
                />
                <Route
                  path={routeConfig.PoSummary}
                  element={
                    <ProtectedPage
                      roles={["admin", "user"]}
                      element={PoSummary}
                      answeredQuestions={answeredQuestions}
                      setAnsweredQuestions={setAnsweredQuestions}
                    />
                  }
                />
                <Route
                  path={routeConfig.po}
                  element={
                    <ProtectedPage
                      roles={["admin", "user"]}
                      element={UserSubmissionAnalysis}
                      heading={"Hello!"}
                      headingClassName="hello-container-heading"
                      description={
                        "I'm here to help you create a concise product spec. Let's start with the basics"
                      }
                      descriptionClassName="hello-container-description"
                      buttonText={"Start Exploring"}
                      endText="[ Know more about Assistant ]"
                      navigationPath="/questions"
                      floatingButton={true}
                    />
                  }
                />
                <Route
                  path={routeConfig.thankyou}
                  element={
                    <ProtectedPage
                      roles={["admin", "user"]}
                      element={UserSubmissionAnalysis}
                      heading={"Thank you for your submission."}
                      description={
                        "Your request is now in queue and will be reviewed and processed on a first-come, first-served basis. Further communication regarding the deliverable will be sent to your registered email."
                      }
                      buttonText={"Back Home"}
                      navigationPath={routeConfig.userDashboard}
                      floatingButton={true}
                      width="832px"
                      pageType="thankyou"
                      showAuraIcon={true}
                    />
                  }
                />
                <Route
                  path={routeConfig.admin}
                  element={
                    <ProtectedPage roles={["admin"]} element={BasicTabs} />
                  }
                />
                <Route path={routeConfig.login} element={<Login />} />
                <Route
                  path={routeConfig.userDashboard}
                  element={
                    <ProtectedPage
                      roles={["admin", "user"]}
                      element={UserDashboard}
                    />
                  }
                  // element={<UserDashboard />}
                />
                <Route
                  path={routeConfig.figmaUpload}
                  element={
                    <ProtectedPage
                      roles={["admin", "user"]}
                      element={UploadScreen}
                    />
                  }
                />
                <Route
                  path={routeConfig.enggLandingPage}
                  element={
                    <ProtectedPage
                      roles={["admin", "user"]}
                      element={UserSubmissionAnalysis}
                      heading={"Hi there!"}
                      headingClassName="hello-container-heading"
                      description={
                        "I'm here to assist you with front-end engineering.Let’s dive into the essentials of turning your design into code."
                      }
                      descriptionClassName="hello-container-description"
                      buttonText={"Start Exploring"}
                      endText="[ Know more about Assistant ]"
                      navigationPath={routeConfig.figmaUpload}
                      floatingButton={true}
                      width="670px"
                    />
                  }
                />
                <Route path={routeConfig.register} element={<RegisterPage />} />
                <Route path="*" element={<Navigate to={routeConfig.login} />} />
              </>
            )}
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
}
