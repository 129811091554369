export const routeConfig = Object.freeze({
  base: "/",
  login: "/login",
  userDashboard: "/user-dashboard",
  productOwner: "/product-owner",
  countDown: "/",
  chatBot: "/chatbot",
  techLead: "/techlead",
  po: "/polanding",
  chat: "/chat",
  admin: "/admin",
  figmaUpload: "/figma-upload",
  enggLandingPage: "/engg-landingpage",
  questions: "/questions",
  PoSummary: "/po-summary",
  thankyou: "/thankyou",
  register:"/register"
});
