export const INCREMENT = "INCREMENT";
export const ABOUT = "About";
export const MOCK_URL =
  "https://mocki.io/v1/061f8c00-b6dc-4df6-88a4-3276e8d029a9";
export const DAYS = "DAYS";
export const HOURS = "HOURS";
export const MIN = "MIN";
export const SEC = "SEC";
export const BANNER_TARGET_TIME = "2024-08-15T00:00:00";
export const AURA_BASE = "https://api.aura.divami.com";
export const FULL_NAME = "Full Name";
export const WORK_EMAIL = "Work Email";
export const COMPANY = "Company";
export const MESSAGE = "Message";
export const PO_ACCESSED = "PO Accessed";
export const TL_ACCESSED = "TL Accessed";
export const YES = "Yes";
export const NO = "No";
export const NONE = "None";
export const CREATE_USER = "Create User";
export const CREATE = "Create";
export const NO_DATA_AVAILABLE = "No Data Available";
export const NAME = "Name";
export const EMAIL = "Email";
export const FIGMA_URL = "Figma Url";
export const STATUS = "Status";
export const ACTION = "Action";
export const EDIT = "Edit";
export const GIT_URL = "Git Url";
export const HOSTED_URL = "Hosted Url";
export const S3_URL = "S3 Url";
export const PROJECT_NAME = "Project Name";
export const SCREEN_NAME = "Screen Name";
export const NEXT = "Next";
export const FIRST_NAME = "First Name";
export const LAST_NAME = "Last Name";
export const DOCUMENT_URL = "User Confirmed Spec";
export const TITLE = "Title";
export const DELIVER = "Deliver";
export const THANK_YOU = " Thank you for providing all these details!";
export const UPLOAD_FILE = "Upload File";
export const FINAL_DELIVERED_SPEC = "Final Delivered Spec";
export const CURRENTLY_AVAILABLE =
  "Our site is crafted for desktops! For an optimal experience, please visit us on a larger screen";
export const KNOW_MORE_LINK = "https://aura.divami.com/aura-blogs/aura-assistants"