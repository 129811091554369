import React from 'react';
import Button from '@mui/material/Button';
import './CustomButton.css';

interface CustomButtonProps {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
}

const CustomButton: React.FC<CustomButtonProps> = ({ text, onClick, disabled, className }) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      className={`custom-button ${className}`}
      sx={{
        backgroundColor: !disabled ? "#E0E3E6" : "#FF0404",
        textTransform: "none",
        fontFamily: "TenorSans",
        "&:hover": {
          backgroundColor: "#FF0404",
          border: "none",
          boxShadow: "none",
          fontFamily: "TenorSans",
          textTransform: "none",
        },
        "&.Mui-disabled": {
          color: "#ffffff",
        },
      }}
    >
      {text}
    </Button>
  );
};

export default CustomButton;
