import { useNavigate } from "react-router-dom";
import "./userSubmissionAnalysis.css";
import React from "react";
import infoButton from "../../assets/Images/info-icon.svg";
import cancelIcon from "../../assets/Images/cross-icon.svg";
import arrow from "../../assets/Images/arrow.svg";
import { routeConfig } from "../../constants/routeConfig";
import auraIcon from "../../assets/Images/auraIcon.png";
import { KNOW_MORE_LINK } from "../../constants";
interface iUserSubmissionAnalysisProps {
  heading: string;
  description: string;
  buttonText: string;
  navigationPath?: string;
  endText?: string;
  headingClassName?: string;
  descriptionClassName?: string;
  floatingButton?: boolean;
  width?: string;
  pageType?: string;
  showAuraIcon?: boolean; 
}
const UserSubmissionAnalysis: React.FC<iUserSubmissionAnalysisProps> = ({
  heading,
  description,
  buttonText,
  endText,
  navigationPath,
  headingClassName,
  descriptionClassName,
  floatingButton,
  width,
  pageType,
  showAuraIcon, 
}) => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate(navigationPath || "");
  };
  return (
    <div className="submission-completed-container">
      {floatingButton && (
        <div
          className="info-hello-cancel-container"
          style={
            pageType ? { marginBottom: "0" } : { marginBottom: "57.5px" }
          }
        >
          <div className="info-container">
            <img src={infoButton} alt="info" className="info-icon" />
            <p className="hover-content">
              Divami reserves the right to accept or reject any submissions
              based on our own internal criteria of suitability and evaluation.
            </p>
          </div>
          {!pageType && (
            <div className="cancel-container">
              <img
                src={cancelIcon}
                alt="cancel"
                className="cancel-icon"
                onClick={() => {
                  navigate(routeConfig.userDashboard);
                }}
              />
            </div>
          )}
        </div>
      )}
      <div className="submission-text-container">
        {showAuraIcon&&(<div className="submission-aura-icon-container">
          <img src={auraIcon} alt="aura" className="submission-aura-icon"/>
        </div>)}
        <div className="submission-inner-container">
          <div className={`submission-text-heading ${headingClassName || ""}`}>
            {heading}
          </div>
          <div
            className={`submission-text-description ${descriptionClassName || ""}`}
            style={{ width: width || "540px" }}
          >
            {description}
          </div>

          <div className="submission-text-button-container">
            <button
              className="submission-text-button"
              onClick={handleButtonClick}
            >
              {buttonText}
              <img src={arrow} alt="arrow" className="arrow" />
            </button>
          </div>
        </div>
        {endText && <a className="initial-text-end-text" href={KNOW_MORE_LINK} target="_blank" rel="noreferrer">{endText}</a>}
      </div>
    </div>
  );
};

export default UserSubmissionAnalysis;
