import { useState } from "react";
import Axios, { AxiosResponse, AxiosError } from "axios";
import { AURA_BASE } from "../constants";

interface RequestInformationTypes {
  name?: string;
}
type RequestBody = object | FormData;

const usePost = () => {
  const [totaldata, setTotalData] = useState<AxiosResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError | null | unknown>(null);
  const [requestInformation, setRequestInformation] = useState<
    RequestInformationTypes | undefined
  >({});

  const doPost = async (
    url: string,
    body: RequestBody,
    contentType: string = "application/json",
    requestInformation?: RequestInformationTypes,
  ) => {
    setLoading(true);
    setRequestInformation(requestInformation);

    try {
      const token = sessionStorage.getItem("access_token");
      const Url = `${AURA_BASE}/${url}`;
      const headers: { [key: string]: string } = {
        Authorization: `Bearer ${token}`,
      };

      if (!(body instanceof FormData)) {
        headers["Content-Type"] = contentType;
      }

      const response = await Axios.post(Url, body, { headers });

      setTotalData(response);

      return response;
    } catch (error: unknown) {
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { totaldata, loading, error, doPost, requestInformation };
};

export default usePost;
