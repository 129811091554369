import React from "react";
import TextField from "@mui/material/TextField";
import "./TextFieldWithButton.css";
import CustomButton from "../CustomButton";

interface CustomTextFieldProps {
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onButtonClick: () => void;
  buttonText: string;
  disabled?: boolean;
  className?: string;
}

const CustomTextField: React.FC<CustomTextFieldProps> = ({
  placeholder,
  value,
  onChange,
  onButtonClick,
  buttonText,
  disabled,
  className,
}) => {
  return (
    <TextField
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      multiline
      variant="outlined"
      fullWidth
      minRows={1}
      maxRows={5}
      className={`custom-textfield ${className}`}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "100px",
          backgroundColor: "#F1F1F1",
          marginBottom: "60px",
          "& fieldset": {
            borderColor: "transparent",
          },
          "&:hover fieldset": {
            borderColor: "transparent",
          },
          "&.Mui-focused fieldset": {
            borderColor: "transparent",
          },
        },
      }}
      InputProps={{
        endAdornment: (
          <CustomButton
            text={buttonText}
            onClick={onButtonClick}
            disabled={!value.trim()}
            className={disabled ? "disabled-button" : ""}
          />
        ),
      }}
    />
  );
};

export default CustomTextField;
