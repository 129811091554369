import  { useEffect, useState } from "react";
import Lottie from "react-lottie-player";
// import Spline from "@splinetool/react-spline";
import animationData from "../../assets/Loader.json"; 
import "./loadingPageStyles.css";
import { THANK_YOU } from "../../constants";

const LoadingPage = () => {
  const messages = [
    "Analyzing the needs and problems of your primary users",
    "Evaluating the key functionalities of your product",
    "Assessing your product's vision and business model",
    "Analysis complete! Preparing your summary",
  ];
  const [messageIndex, setMessageIndex] = useState(0);

  useEffect(() => {
    const initialPhaseDuration = 25 * 1000;
    const intervalTime = initialPhaseDuration / (messages.length - 1);

    const interval = setInterval(() => {
      setMessageIndex((prevIndex) => {
        if (prevIndex < messages.length - 2) {
          return prevIndex + 1;
        } else {
          clearInterval(interval);
          return prevIndex;
        }
      });
    }, intervalTime);

    const timeout = setTimeout(() => {
      setMessageIndex(messages.length - 1);
    }, initialPhaseDuration);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [messages.length]);

  return (
    <div className="submission-inprogress-container">
      <div className="submission-inprogress-text-container">
        <div className="submission-inprogress-text-data">
          <div className="submission-inprogress-thanks-details">
            {THANK_YOU}
          </div>
          
        </div>
        {/* <div className="submission-inprogress-image-container"> */}
          {/* <Spline
            scene="https://prod.spline.design/59AQVMMd6JDU40lo/scene.splinecode"
            style={{ width: "500px", height: "500px" }}
          /> */}
          <Lottie
            loop
            animationData={animationData}
            play
            style={{ width: 150, height: 100 }}
          />
          <div className="submission-inprogress-analysis-details">
            {messages[messageIndex]}
            <span className="loading-dots">
              <span></span><span></span><span></span>
            </span>
          </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default LoadingPage;
