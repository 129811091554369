import { useState } from "react";
import "./inputField.css";
import error from '../../assets/Images/error.svg';

interface iInputFieldProps {
  label?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  placeholder?: string;
  register?: object;
  errorMessage?: string;
  type?: string;
  value?: string;
  multiline?: boolean; 
  minRows?: number;
  maxRows?: number; 
  children?: React.ReactNode;
}

const TextField = ({
  label,
  value,
  type,
  register,
  placeholder,
  onChange,
  errorMessage,
  multiline = false,
  minRows = 1,

}: iInputFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  if (multiline) {
    return (
      <div className="inputFieldWrapper">
        {label && <div className="labelText">{label}</div>}
        <textarea
          value={value}
          {...register}
          placeholder={placeholder}
          onChange={onChange}
          className={`inputField ${errorMessage ? 'inputError' : ''}`}
          rows={minRows}
          style={{ resize: 'none', overflow: 'auto' }} 
        />
        {errorMessage && (
          <img src={error} alt="error" className="errorIcon" />
        )}
      </div>
    );
  }

  return (
    <div className="inputFieldWrapper">
      {label && <div className="labelText">{label}</div>}
      <input
        value={value}
        type={
          type === "password" ? (!showPassword ? "password" : "text") : type
        }
        {...register}
        placeholder={placeholder}
        onChange={onChange}
        className={`inputField ${errorMessage ? 'inputError' : ''}`}
      />
      {type === "password" ? (
        <div
          className={`showPassword ${showPassword ? "passwordShow" : "passwordHide"}`}
          onClick={() => setShowPassword(!showPassword)}
        ></div>
      ) : null}
      {errorMessage && (
        <img src={error} alt="error" className="errorIcon" />
      )}
    </div>
  );
};

export default TextField;