import { ComponentType, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { routeConfig } from "../constants/routeConfig";
import React from "react";

const ProtectedPage = ({
  element: Component,
  roles,
  ...rest
}: {
  element: ComponentType<ReactNode>;
  roles?: string[];
}) => {
  const user = JSON.parse(sessionStorage.getItem("user") ?? "{}");
  if (Object.keys(user).length === 0) {
    return <Navigate to={routeConfig.login} />;
  }

  if (!user?.role.id) {
    return <Navigate to={routeConfig.login} />;
  }

  if (user && roles && !roles.includes(user?.role.name)) {
    sessionStorage.clear();
    return <Navigate to="/" />;
  }

  return <Component {...rest} />;
};

export default ProtectedPage;
