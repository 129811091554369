import { Step, StepLabel, Stepper } from "@mui/material";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { routeConfig } from "../../constants/routeConfig";
import CustomModal from "../Modal/model";
import "./QuestionsComponent.css";
import QontoStepIcon, { QontoConnector } from "./StepperUtils";

interface Question {
  id: number;
  key: string;
  question: string;
  answer: string;
  message: string;
  displayName: string;
}

interface QuestionsComponentProps {
  answeredQuestions: {
    [key: string]: { question: string; answer: string; message: string };
  };
  setAnsweredQuestions: React.Dispatch<
    React.SetStateAction<{
      [key: string]: { question: string; answer: string };
    }>
  >;
}

const questionsData: Question[] = [
  {
    id: 1,
    key: "product_description",
    question: "What's it called, and can you give me a brief description?",
    answer: "",
    message: "",
    displayName: "Product Description",
  },
  {
    id: 2,
    key: "product_primary_users",
    question: "Who are the primary users of your product?",
    answer: "",
    message: "Got it!",
    displayName: "User & Needs",
  },
  {
    id: 3,
    key: "problems_addressed",
    question: "What problems or needs does the product address ?",
    answer: "",
    message: "Interesting!",
    displayName: "Key Functionality",
  },
  {
    id: 4,
    key: "product_functionality",
    question: "How does your product solve these problems ",
    answer: "",
    message: "Understood",
    displayName: "Overall Vision",
  },
  {
    id: 5,
    key: "product_vision",
    question: "So what's your overall vision for the product?",
    answer: "",
    message: "That's a great vision!",
    displayName: "Business Vision",
  },
  {
    id: 6,
    key: "business_model",
    question:
      "What is your business model for the product? (Leave as blank if not applicable: e.g., for internal company use, etc.)",
    answer: "",
    message: "Excellent goal!",
    displayName: "Business Model",
  },
  {
    id: 7,
    key: "competitors",
    question:
      "Are there any main competitors or similar products? If so, please provide their website links, comma-separated.",
    answer: "",
    message: "Got it!",
    displayName: "Competitors",
  },
];

const QuestionsComponent: React.FC<QuestionsComponentProps> = ({
  answeredQuestions,
  setAnsweredQuestions,
}) => {
  const prevQuestionLength = Object.keys(answeredQuestions).length;
  const navigate = useNavigate();
  const [questions, setQuestions] = useState<Question[]>(questionsData);
  const [activeStep, setActiveStep] = useState(0);
  const [currentAnswer, setCurrentAnswer] = useState<string>("");
  const lastAnsweredRef = useRef<HTMLDivElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    setAnsweredQuestions({});
    setQuestions(questionsData);
  }, [setAnsweredQuestions]);

  useEffect(() => {
    if (lastAnsweredRef.current) {
      lastAnsweredRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [answeredQuestions]);
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && currentAnswer.trim() !== "") {
      e.preventDefault();
      if (questions.length > 1 && activeStep !== 6) {
        handleNext(questions[activeStep]);
      } else {
        handleSubmit();
      }
    }
  };

  const handleAnswerChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setCurrentAnswer(e.target.value);
  };

  const handleNext = (question: Question) => {
    if (currentAnswer.trim() !== "") {
      setAnsweredQuestions((prevState) => ({
        ...prevState,
        [question.key]: { question: question.question, answer: currentAnswer },
      }));
      // setQuestions(questions.filter((q) => q.id !== question.id));
      setCurrentAnswer("");
      setActiveStep(question.id);
    }
  };

  const handleSubmit = () => {
    if (currentAnswer.trim() !== "") {
      setAnsweredQuestions((prevState) => ({
        ...prevState,
        [questions[activeStep].key]: {
          question: questions[activeStep].question,
          answer: currentAnswer,
        },
      }));
    }

    setQuestions(questionsData);
    navigate("/po-summary");
  };
  // const handleCancel = () => {
  //   setIsModalOpen(true);
  // };
  const handleConfirmLogout = () => {
    navigate(routeConfig.userDashboard);
    setIsModalOpen(false);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleBackToHome = () => {
    setIsModalOpen(true);
  };

  const hanldeStepClick = (id: number) => {
    if (id < prevQuestionLength + 1) {
      setActiveStep(id);
    }
  };

  console.log("answeredQuestions", answeredQuestions, questions, activeStep);

  return (
    <>
      <div className="questionaire">
        {questions.length > 0 && activeStep < 7 && (
          <div className="numbers">
            <p className="numbers-para">{activeStep + 1}</p>
          </div>
        )}

        <div className="questions-container">
          <div className="question-answered-button-container">
            <div className="answered-questions-container">
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                connector={<QontoConnector />}
                sx={{ paddingTop: "20px" }}
              >
                {questionsData.map((label, i) => (
                  <Step
                    key={label.id}
                    onClick={() => hanldeStepClick(i)}
                    completed={Object.keys(answeredQuestions).length + 1 > i}
                    sx={{
                      ".MuiStepLabel-labelContainer.MuiStepLabel-alternativeLabel ":
                        {
                          position: "absolute",
                          top: "-40px",
                          display: activeStep === i ? "block" : "none",
                        },
                      ".MuiStepLabel-iconContainer": {
                        cursor: "pointer",
                      },
                    }}
                  >
                    <StepLabel StepIconComponent={QontoStepIcon}>
                      {label.displayName}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            {/* <div className="info-cancel-container-questions">
              <div className="info-container">
                <img src={infoButton} alt="info" className="info-icon" />
                <p className="hover-content">
                  Divami reserves the right to accept or reject any submissions
                  based on our own internal criteria of suitability and
                  evaluation.
                </p>
              </div>
              <div className="cancel-container">
                <img
                  src={cancelIcon}
                  alt="cancel"
                  className="cancel-icon"
                  onClick={handleCancel}
                />
              </div>
            </div> */}
          </div>
          {questions.length > 0 && (
            <div className="current-question">
              <p className="message">{questions[activeStep]?.message}</p>
              <p className="question-input">
                {questions[activeStep]?.question}
              </p>

              {answeredQuestions[activeStep]?.answer}
              <TextField
                value={
                  answeredQuestions[questions[activeStep]?.key]?.answer ||
                  currentAnswer
                }
                onChange={handleAnswerChange}
                placeholder="Type your answer here!"
                multiline
                onKeyDown={handleKeyDown}
                variant="outlined"
                fullWidth
                minRows={1}
                maxRows={5}
                className="answer-input"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "100px",
                    backgroundColor: "#F1F1F1",
                    marginBottom: "60px",
                    paddingLeft: "32px",
                    gap: "24px",
                    "& fieldset": {
                      borderColor: "transparent",
                    },
                    "&:hover fieldset": {
                      borderColor: "transparent",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "transparent",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "#CACACA",
                      fontFamily: "TenorSans",
                      fontSize: "16px",
                      opacity: "1",
                    },
                  },
                }}
                InputProps={{
                  endAdornment:
                    questions.length > 1 && activeStep < 6 ? (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleNext(questions[activeStep])}
                        disabled={!currentAnswer.trim()}
                        className="current-question-button"
                        sx={{
                          backgroundColor: !currentAnswer.trim()
                            ? "#E0E3E6"
                            : "#FF0404",
                          textTransform: "none",
                          fontFamily: "TenorSans",
                          "&:hover": {
                            backgroundColor: "#FF0404",
                            border: "none",
                            boxShadow: "none",
                          },
                          "&.Mui-disabled": {
                            color: "#ffffff",
                          },
                        }}
                      >
                        Next
                      </Button>
                    ) : null,
                }}
              />
              {activeStep === 6 && (
                <div className="buttons-container">
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleBackToHome}
                    className="current-question-button "
                    sx={{
                      width: "140px !important",
                      backgroundColor: "#FF0404",
                      textTransform: "none",
                      fontFamily: "TenorSans",
                      "&:hover": {
                        backgroundColor: "#FF0404",
                        border: "none",
                        boxShadow: "none",
                        textTransform: "none",
                        fontFamily: "TenorSans",
                      },
                      "&.Mui-disabled": {
                        color: "#ffffff",
                      },
                    }}
                  >
                    Back to Home
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleSubmit}
                    className="current-question-button "
                    sx={{
                      backgroundColor: "#FF0404",
                      textTransform: "none",
                      fontFamily: "TenorSans",
                      "&:hover": {
                        backgroundColor: "#FF0404",
                        border: "none",
                        boxShadow: "none",
                        textTransform: "none",
                        fontFamily: "TenorSans",
                      },
                      "&.Mui-disabled": {
                        color: "#ffffff",
                      },
                    }}
                  >
                    Submit
                  </Button>
                </div>
              )}
            </div>
          )}
        </div>
        <CustomModal
          open={isModalOpen}
          title="Do You Want To Go Home..?"
          onClose={handleCloseModal}
          onConfirm={handleConfirmLogout}
          confirmText="Back Home"
          actionConfirmButtonClassName="buttonForHome"
        >
          {"If You Go Back Your Answers Will Be Lost"}
        </CustomModal>
      </div>
    </>
  );
};

export default QuestionsComponent;
