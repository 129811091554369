import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./userDashboardStyles.css";
import { routeConfig } from "../../constants/routeConfig";
import RoleCard from "../../common/RoleCard/rolecard";
import { messages } from "../../constants/messages";
import useFetch from "../../services/apiService";
import { Alert, Snackbar } from "@mui/material";

const UserDashboard = () => {
  const navigate = useNavigate();
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [showmessage, setShowMessage] = useState<string>("");
  const { data, doFetch, loading } = useFetch();
  const handleCardClick = async (id: number) => {
    setSelectedId(id);
    if (id === 1) {
      const url = "aura/po/chat/user";
      await doFetch(url, {
        method: "GET",
      });
    } else if (id === 2) {
      const url = "aura/tl/codegen/user";
      await doFetch(url, {
        method: "GET",
      });
    }
  };
  useEffect(() => {
    if (data && !loading && selectedId === 2) {
      if (!data?.data?.data?.figma_active) {
        console.log(!data?.data?.data[0]?.figma_active, "active");

        navigate(routeConfig.enggLandingPage);
      } else {
        setShowMessage(messages.INPROGRESS);
        setShowToast(true);
      }
    } else if (data && !loading && selectedId === 1) {
      if (!data?.data?.data?.po_chat_active) {
        navigate(routeConfig.po);
      } else {
        setShowMessage(messages.CHATINPROGRESS);
        setShowToast(true);
      }
    } else if (loading) {
      console.log("Loading...");
    }
  }, [data, loading, selectedId]);

  return (
    <div className="userDashboard">
      <div className="headerText">{messages.HEADERTEXT}</div>
      <RoleCard handleCardClick={handleCardClick} />
      <Snackbar
        open={showToast}
        autoHideDuration={10000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setShowToast(false)}
      >
        <Alert
          onClose={() => setShowToast(false)}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {showmessage}
        </Alert>
      </Snackbar>
    </div>
  );
};
export default UserDashboard;
