import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Button } from "../../common/Button";
import "./model.css";
interface CustomModalProps {
  open: boolean;
  title: string;
  onClose: () => void;
  onConfirm?: () => void;
  confirmText?: string;
  children: React.ReactNode;
  actionConfirmButtonClassName: string;
}

const CustomModal: React.FC<CustomModalProps> = ({
  open,
  title,
  onClose,
  onConfirm,
  confirmText = "Confirm",
  children,
  // actionConfirmButtonClassName,
}) => {
  const isArray = Array.isArray(children);
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <div className="modal-list-heading">{title}</div>
      </DialogTitle>
      <DialogContent>
        {isArray ? (
          <ol className="modal-list-container">
            {children.map((child, index) => (
              <li key={index}>{child}</li>
            ))}
          </ol>
        ) : (
          <div className="modal-child-container">{children}</div>
        )}
      </DialogContent>
      <DialogActions>
        {onConfirm && (
          <Button onClick={onConfirm} className={`modal-confirm-button`}>
            {confirmText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CustomModal;
