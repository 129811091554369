import React, { useEffect, useState } from "react";
import usePost from "../../services/apiPostService";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import "./Summary.css";
import CustomTextField from "../../common/TextFieldWithButton/TextFieldWithButton";
import CustomButton from "../../common/CustomButton";
import LoadingPage from "../LoadingPage/loadingPage";
import infoButton from "../../assets/Images/info-button.svg";
import star from "../../assets/Images/star-icon.svg";
import backButton from "../../assets/Images/Back-icon.svg";
import useFetch from "../../services/apiService";
interface ApiData {
  status: string;
  data: {
    chat: {
      updated_at: string;
      title: string | null;
      created_by_id: number | null;
      is_archived: boolean;
      created_at: string;
      id: number;
      user_id: number;
      deleted_at: string | null;
      updated_by_id: number | null;
    };
    messages: {
      request: {
        chat_id: number;
        sender_id: number;
        message: string;
        created_at: string;
        response_number: number;
        request_id: number | null;
        receiver_id: number;
        id: number;
        message_type: string;
        updated_at: string;
        prompt: string | null;
      };
      response: {
        chat_id: number;
        sender_id: number;
        message: {
          type: string;
          response: {
            about_product?: string;
            background?: string;
            core_functionalities?: string[];
            spec?: {
              apps?: {
                app_name: string;
                modules: {
                  module_name: string;
                  description: string;
                  features: string[];
                  flow: string[];
                }[];
              }[];
            };
            [key: string]: unknown;
          };
        };
        created_at: string;
        response_number: number;
        request_id: number | null;
        receiver_id: number;
        id: number;
        message_type: string;
        updated_at: string;
        prompt: string | null;
      };
    }[];
  };
  message: string;
}

interface PoSummaryProps {
  answeredQuestions: { [key: string]: { question: string; answer: string } };
  setAnsweredQuestions: React.Dispatch<
    React.SetStateAction<{
      [key: string]: { question: string; answer: string };
    }>
  >;
}

const PoSummary: React.FC<PoSummaryProps> = ({
  answeredQuestions,
  setAnsweredQuestions,
}) => {
  const [apiData, setApiData] = useState<ApiData | null>(null);
  const [editingQuestion, setEditingQuestion] = useState<string | null>(null);
  const [currentAnswer, setCurrentAnswer] = useState<string>("");
  const [highlightedQuestions, setHighlightedQuestions] = useState<string[]>(
    [],
  );
  const [loadingState, setLoadingState] = useState<boolean>(true);
  // const [updatedQuestions, setUpdatedQuestions] = useState<{
  //   [key: string]: string;
  // }>({});
  const { doPost, totaldata, loading } = usePost();
  const navigate = useNavigate();

  const transformData = (answeredQuestions: {
    [key: string]: { question: string; answer: string };
  }) => {
    return {
      data: {
        product_description: answeredQuestions["product_description"]?.answer,
        product_primary_users:
          answeredQuestions["product_primary_users"]?.answer,
        problems_addressed: answeredQuestions["problems_addressed"]?.answer,
        product_functionality:
          answeredQuestions["product_functionality"]?.answer,
        product_vision: answeredQuestions["product_vision"]?.answer,
        business_model: answeredQuestions["business_model"]?.answer,
        competitors: answeredQuestions["competitors"]?.answer,
      },
    };
  };

  const { data, doFetch, loading: dataLoading } = useFetch();

  const fetchUserStatus = async () => {
    const url = "aura/po/chat/user";
    await doFetch(url, {
      method: "GET",
    });
  };

  useEffect(() => {
    console.log(
      data,
      dataLoading,
      data?.data?.data?.po_chats?.at(0)?.status_id,
    );
    if (data?.data?.data) {
      if (data?.data?.data?.po_chats?.at(0)?.status_id < 4) {
        console.log("regen");
        regenerateSummary();
      } else {
        console.log("init");
        fetchData();
      }
    } else {
      console.log("else");
    }
  }, [data]);

  const fetchData = async () => {
    const transformedData = transformData(answeredQuestions);
    await doPost("aura/po/chat/init", transformedData);
  };

  useEffect(() => {
    if (Object.keys(answeredQuestions).length > 0) {
      sessionStorage.removeItem("answeredQuestions");
      if (Object.keys(answeredQuestions).length > 0) {
        // console.log(answeredQuestions,"if")
        sessionStorage.setItem(
          "answeredQuestions",
          JSON.stringify(answeredQuestions),
        );
      }
    }
  }, [answeredQuestions]);

  useEffect(() => {
    const savedQuestions = sessionStorage.getItem("answeredQuestions");
    if (savedQuestions) {
      setAnsweredQuestions(JSON.parse(savedQuestions));
    }
    // console.log("AnsweredQuestions getItem", savedQuestions);
    // fetchData();
    fetchUserStatus();
  }, []);

  useEffect(() => {
    if (editingQuestion) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [editingQuestion]);

  useEffect(() => {
    if (totaldata) {
      const { data } = totaldata;
      setApiData(data);

      if (data?.data?.messages?.[0]?.response?.message?.type === "question") {
        const highlightedKeys =
          data.data.messages[0].response.message.response.map(
            (item: { input: string }) => item.input,
          );
        setHighlightedQuestions(highlightedKeys);
      } else if (
        data?.data?.messages?.[0]?.response?.message?.type === "clear"
      ) {
        setHighlightedQuestions([]);
      }
    }
  }, [totaldata]);

  const handleEditQuestion = (key: string) => {
    setEditingQuestion(key);
    setCurrentAnswer(answeredQuestions[key].answer);
  };

  const handleProceedAndGenerate = async () => {
    try {
      if (!apiData?.data.chat.id) {
        throw new Error("Chat ID is not available.");
      }
      const chatId = apiData.data.chat.id;
      const transformedData = transformData(answeredQuestions);
      await doPost(`aura/po/chat/${chatId}/confirm`, transformedData);
      navigate("/thankyou");
    } catch (error) {
      // console.error("Error during API call:", error);
    }
  };

  const handleUpdateAnswer = () => {
    if (editingQuestion) {
      setAnsweredQuestions((prevState) => {
        const updatedState = {
          ...prevState,
          [editingQuestion]: {
            ...prevState[editingQuestion],
            answer: currentAnswer,
          },
        };

        return updatedState;
      });
      setEditingQuestion(null);
      setCurrentAnswer("");
      // setUpdatedQuestions((prevState) => ({
      //   ...prevState,
      //   [editingQuestion]: "Updated",
      // }));
    }
  };

  const regenerateSummary = async () => {
    if (apiData?.data.chat.id || data?.data?.data?.po_chats?.at(0)?.id) {
      const chatId =
        apiData?.data?.chat.id || data?.data?.data?.po_chats?.at(0)?.id;
      const transformedData = transformData(answeredQuestions);
      // console.log(transformedData,"transformedData");
      await doPost(`aura/po/chat/${chatId}/regenerate`, transformedData);
      // setUpdatedQuestions({});
    }
  };

  const handleRegenerate = () => {
    regenerateSummary();
  };
  useEffect(() => {
    setTimeout(() => {
      setLoadingState(false);
    }, 30000);
  }, []);

  return (
    <div
      className={`summary-container ${loading || loadingState ? "extra-class" : ""}`}
    >
      {loading || loadingState ? (
        <LoadingPage />
      ) : (
        <>
          <div className="summary-details">
            <div
              className={`summary-description  ${editingQuestion ? "dimmed" : ""}`}
            >
              {apiData && (
                <div>
                  {apiData.data.messages &&
                  apiData.data.messages[0].response.message.type ===
                    "question" ? (
                    <div>
                      <p className="response-text">
                        Hi there! Sorry, we could not understand the product as
                        well as we would have liked to. Could you please improve
                        your inputs and click on &quot;Renegerate&quot;! Eager
                        to see what you want to build !
                      </p>
                    </div>
                  ) : apiData.data.messages &&
                    apiData.data.messages[0].response.message.type ===
                      "clear" ? (
                    <div>
                      <p className="summary-heading">
                        Here&apos;s a quick summary of the information
                        you&apos;ve provided:
                      </p>
                      <div className="heading-container">
                        <img src={star} alt="star" />
                        <h3 className="summary-subheading">About Product</h3>
                      </div>
                      <p className="summary-paragraph">
                        {
                          apiData.data.messages[0].response.message.response
                            .about_product
                        }
                      </p>
                      <div className="heading-container">
                        <img src={star} alt="star" />
                        <h3 className="summary-subheading">Background</h3>
                      </div>
                      <p className="summary-paragraph">
                        {
                          apiData.data.messages[0].response.message.response
                            .background
                        }
                      </p>
                      <div className="heading-container">
                        <img src={star} alt="star" />
                        <h3 className="summary-subheading">
                          Core Functionalities
                        </h3>
                      </div>
                      <ul className="summary-functionalities-list">
                        {apiData.data.messages[0].response.message.response.core_functionalities?.map(
                          (func: string, index: number) => (
                            <li key={index}>{func}</li>
                          ),
                        )}
                      </ul>
                      <div className="heading-container">
                        <img src={star} alt="star" />
                        <h3 className="summary-subheading">Structure</h3>
                      </div>
                      <ul className="summary-specs-list">
                        {apiData?.data?.messages[0]?.response?.message?.response?.spec?.apps?.map(
                          (app, index) => (
                            <li key={index}>
                              <h5 className="specs-subheading">
                                {app.app_name}
                              </h5>
                              {app.modules.map((module, modIndex) => (
                                <div key={modIndex}>
                                  <h6 className="specs-subheading">
                                    {module.module_name}
                                  </h6>
                                  <p className="module-feature">
                                    <span className="module-feature-heading">
                                      Description:
                                    </span>{" "}
                                    {module.description}
                                  </p>
                                  <p className="module-feature">
                                    <span className="module-feature-heading">
                                      Features:
                                    </span>{" "}
                                    {module.features.join(", ")}
                                  </p>
                                  <p className="module-feature">
                                    {" "}
                                    <span className="module-feature-heading">
                                      Flow:
                                    </span>
                                  </p>
                                  <ul className="module-flow-list">
                                    {module.flow.map((flowItem, flowIndex) => (
                                      <li key={flowIndex}>{flowItem}</li>
                                    ))}
                                  </ul>
                                </div>
                              ))}
                            </li>
                          ),
                        )}
                      </ul>
                    </div>
                  ) : null}
                </div>
              )}
            </div>

            <div
              className={`summary-responses  ${editingQuestion ? "expanded" : ""}`}
            >
              {editingQuestion ? (
                <>
                  <div className="info-icon-container">
                    <div
                      className="back-button"
                      onClick={() => setEditingQuestion(null)}
                    >
                      <img src={backButton} alt="info" className="info-icon" />
                    </div>
                    <div className="info-container-summary">
                      <img src={infoButton} alt="info" className="info-icon" />
                      <p className="hover-content">
                        Divami reserves the right to accept or reject any
                        submissions based on our own internal criteria of
                        suitability and evaluation.
                      </p>
                    </div>
                  </div>
                  <div className="question-answer">
                    <p className="question-input">
                      {answeredQuestions[editingQuestion].question}
                    </p>
                    <div>
                      <CustomTextField
                        placeholder="Edit your answer..."
                        value={currentAnswer}
                        onChange={(e) => setCurrentAnswer(e.target.value)}
                        onButtonClick={handleUpdateAnswer}
                        className="update-field"
                        buttonText="Update"
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="responses-heading-container">
                    <h3 className="response-heading">Your Inputs</h3>
                    <div className="info-cancel-container-summary">
                      <div className="info-container">
                        <img
                          src={infoButton}
                          alt="info"
                          className="info-icon"
                        />
                        <p className="hover-content">
                          Divami reserves the right to accept or reject any
                          submissions based on our own internal criteria of
                          suitability and evaluation.
                        </p>
                      </div>
                    </div>
                  </div>
                  <ul className="responses-unordered-list">
                    {Object.entries(answeredQuestions).map(
                      ([key, { question, answer }]) => {
                        // console.log(`Key: ${key}, Highlight: ${highlightedQuestions.includes(key)}`);
                        return (
                          <li
                            key={key}
                            style={{
                              color: highlightedQuestions.includes(key)
                                ? "#FF7A00"
                                : "#2B2B2B",
                            }}
                            className="response-list"
                            onClick={() => handleEditQuestion(key)}
                          >
                            {/* {updatedQuestions[key] && (
                              <p className="update-message">
                                {updatedQuestions[key]}
                              </p>
                            )} */}
                            <p className="summary-question">{question}</p>
                            <p className="summary-answer">{answer}</p>
                          </li>
                        );
                      },
                    )}
                  </ul>
                  <div className="regenrate-container">
                    <CustomButton
                      text={"Regenerate Summary"}
                      onClick={handleRegenerate}
                      className="regenerate-button"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
      {!editingQuestion && (
        <div
          className={`summary-details ${loading || loadingState ? "hide" : ""}`}
        >
          {!loading && !loadingState && (
            <div className="review-field">
              <p>
                Please review the summary above. If everything looks correct,
                you can proceed to generate the spec.
              </p>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleProceedAndGenerate}
                className={
                  highlightedQuestions.length > 0
                    ? "disable-generate-spec"
                    : "generate-spec-button"
                }
                disabled={highlightedQuestions.length > 0 ? true : false}
              >
                Proceed and Generate
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PoSummary;
