import { useEffect, useState } from "react";
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Snackbar,
  LinearProgress,
  IconButton,
  Tooltip,
} from "@mui/material";

import useFetch from "../../services/apiService";
import "./tlListing.css";
import usePut from "../../services/apiPutService";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import {
  AURA_BASE,
  COMPANY,
  EDIT,
  EMAIL,
  FIGMA_URL,
  GIT_URL,
  HOSTED_URL,
  NAME,
  NEXT,
  PROJECT_NAME,
  S3_URL,
  STATUS,
} from "../../constants";

interface Figma {
  figma_url: string | null;
  s3_url: string;
  id: number;
}

interface User {
  email: string;
  first_name: string;
  company: string | null;
}

interface Status {
  status: string;
}

interface RowData {
  id: number;
  project_name: string | null;
  screen_name: string | null;
  git_url: string | null;
  host_url: string | null;
  figma: Figma;
  user: User;
  status: Status;
}
interface Status {
  id: number;
  status: string;
}
const TlListing = () => {
  const [tldata, setTlData] = useState<RowData[]>([]);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [tempData, setTempData] = useState<RowData | null>(null);
  const [searchText, setSearchText] = useState<string>("");
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };
  const [generateClick, setGenerateClick] = useState<boolean>(false);
  const [selectedStatusId, setSelectedStatusId] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [statusList, setStatusList] = useState<Status[]>([]);

  const [errors, setErrors] = useState<string>("");
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setCurrentPage(value);
  };
  const { doPut } = usePut();
  const handleEditClick = (index: number) => {
    const selectedRow = tldata.find((row) => row.id === index);
    if (selectedRow) {
      setEditIndex(index);
      setTempData({ ...selectedRow });
    }
  };

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: keyof RowData,
  ) => {
    if (tempData) {
      setTempData({
        ...tempData,
        [field]: e.target.value as string,
      });
    }
  };
  const handleStatusChange = async (e: SelectChangeEvent<string>) => {
    if (tempData) {
      setTempData({
        ...tempData,
        status: {
          id: parseInt(e.target.value),
          status: e.target.value,
        },
      });
    }
  };

  const handleSaveClick = async (id: number) => {
    await doPut(`aura/tl/codegen/${id}`, {
      figma_url: tempData?.figma.figma_url,
      project_name: tempData?.project_name,
      git_url: tempData?.git_url,
      host_url: tempData?.host_url,
      screen_name: tempData?.screen_name,
      status_id: tempData?.status.id,
    });

    if (editIndex !== null && tempData) {
      setEditIndex(null);
      setTempData(null);
    }
  };

  const handleCancelClick = () => {
    setEditIndex(null);
    setTempData(null);
  };
  const handleHeaderStatusChange = (event: SelectChangeEvent<string>) => {
    const selectedId = event.target.value;
    setSelectedStatusId(selectedId);
    setCurrentPage(1);
  };
  const { doFetch, data, loading } = useFetch();
  const buildUrl = () => {
    let url = "aura/tl/codegen/";
    const params = new URLSearchParams();
    if (currentPage > 0) params.append("page", currentPage.toString());
    if (selectedStatusId !== "") params.append("status", selectedStatusId);
    if (searchText.trim() !== "")
      params.append("search_text", searchText.toString());

    const queryString = params.toString();
    if (queryString) {
      url += `?${queryString}`;
    }

    return url;
  };

  useEffect(() => {
    const token = sessionStorage.getItem("access_token");
    const fetchStatusList = async () => {
      try {
        const response = await fetch(`${AURA_BASE}/aura/tl/codegen/status`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();

        if (data.status === "Success") {
          const statuses = data.data.map(
            (item: { id: number; status: string }) => ({
              id: item.id,
              status: item.status,
            }),
          );

          setStatusList(statuses);
        } else {
          setErrors("SomeThing Went Wrong");
        }
      } catch (error) {
        setErrors("Something Went Wrong");
      }
    };

    fetchStatusList();
  }, []);

  const getButtonLabel = (row: RowData) => {
    if (row.status.id == 1) {
      return "Upload Figma Url";
    } else if (row.status.id === 2) {
      return "Upload Project Details";
    } else if (row.status.id === 9) {
      return "Generate";
    } else if (row.status.id === 3) {
      return "CodeGen In Progress";
    } else if (row.status.id === 4) {
      return "Upload Git Url";
    } else if (row.status.id === 5) {
      return "Upload Host Url";
    } else if (row.status.id === 6) {
      return "deliver in progress";
    } else if (row.status.id === 7) {
      return "delivered";
    } else if (row.status.id === 8) {
      return "regenrate";
    }
  };
  // const { doPost } = usePost();
  const handleGenerate = async (row: RowData) => {
    if (row.status.id === 1 || row.status.id === 2) {
      await doPut(`aura/tl/codegen/${row.id}/status`, {
        status_id: 8,
      });
    }
    //  else if (row.status.id === 9 || row.status.id === 8) {
    //   console.log("id", row.id);
    //   console.log("status", row.figma.id);
    //   await doPost(`aura/tl/codegen/k6`, {
    //     figma_id: row.figma.id,
    //   });
    // }
    setGenerateClick(!generateClick);
  };
  // const getButtonValue = (row: RowData) => {
  //   if (row.status.id === 9) {
  //     return "Generate";
  //   }
  //   if (row.status.id === 1 || row.status.id === 2) {
  //     return "Cancel";
  //   }
  //   if (
  //     row.status.id === 4 ||
  //     row.status.id === 5 ||
  //     row.status.id === 6 ||
  //     row.status.id === 7
  //   ) {
  //     return "Deliver";
  //   }
  //   if (row.status.id === 8) {
  //     return "Re-Generate";
  //   }
  // };
  useEffect(() => {
    if (data?.data?.data) {
      setTlData(data.data.data);
      setTotalPages(data.data.page.total_pages);
      setCurrentPage(data.data.page.number);
    }
  }, [data, editIndex, handleGenerate, handleSaveClick, generateClick]);
  useEffect(() => {
    const fetchData = async () => {
      const url = buildUrl();
      try {
        await doFetch(url);
      } catch (error) {
        setErrors("Something Went Wrong");
      }
    };

    fetchData();
  }, [currentPage, searchText, selectedStatusId, editIndex, generateClick]);
  return (
    <div className="admin-tabs-table-container">
      <TextField
        id="outlined-basic"
        onChange={handleSearch}
        label="Search"
        variant="outlined"
        className="searchField"
      />
      {loading ? (
        <div className="loadingContainer">
          <LinearProgress />
        </div>
      ) : (
        <TableContainer
          sx={{ height: "65vh", overflowY: "scroll" }}
          component={Paper}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="tableHead">{NAME}</TableCell>
                <TableCell className="tableHead">{EMAIL}</TableCell>
                <TableCell className="tableHead">{COMPANY}</TableCell>
                <TableCell className="tableHead">{S3_URL}</TableCell>
                <TableCell className="tableHead">{PROJECT_NAME}</TableCell>
                <TableCell className="tableHead">{FIGMA_URL}</TableCell>

                <TableCell className="tableHead">{GIT_URL}</TableCell>
                <TableCell className="tableHead">{HOSTED_URL}</TableCell>
                {/* 
                <TableCell className="tableHead">{SCREEN_NAME}</TableCell> */}
                {/* <TableCell className="tableHead">{ACTION}</TableCell> */}
                <TableCell className="tableHead">
                  <FormControl className="select-dropdown" fullWidth>
                    <InputLabel>{STATUS}</InputLabel>
                    <Select
                      value={selectedStatusId}
                      onChange={handleHeaderStatusChange}
                      label="Status"
                    >
                      {statusList.map((status) => (
                        <MenuItem key={status.id} value={status.id}>
                          {status.status}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell className="tableHead">{NEXT}</TableCell>
                <TableCell className="tableHead">{EDIT}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tldata.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={12} style={{ textAlign: "center" }}>
                    No data available
                  </TableCell>
                </TableRow>
              ) : (
                tldata.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell className="tableCell">
                      {row.user.first_name}
                    </TableCell>
                    <TableCell className="tableCell">
                      {row.user.email}
                    </TableCell>
                    <TableCell className="tableCell">
                      {row.user.company || "-"}
                    </TableCell>
                    <TableCell className="tableCell">
                      <a
                        href={row.figma.s3_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {"Download" || "-"}
                      </a>
                    </TableCell>
                    <TableCell className="tableCell">
                      {editIndex === row.id ? (
                        <TextField
                          className="textField"
                          value={tempData?.project_name || ""}
                          onChange={(e) => handleFieldChange(e, "project_name")}
                        />
                      ) : (
                        row.project_name || "-"
                      )}
                    </TableCell>
                    <TableCell className="tableCell">
                      {editIndex === row.id ? (
                        <TextField
                          className="textField"
                          value={tempData?.figma.figma_url || ""}
                          onChange={(e) =>
                            setTempData({
                              ...tempData,
                              figma: {
                                ...tempData?.figma,
                                figma_url: e.target.value,
                              },
                            })
                          }
                        />
                      ) : (
                        <a
                          className="tableCell"
                          href={row.figma.figma_url || "#"}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {row.figma.figma_url || ""}
                        </a>
                      )}
                    </TableCell>
                    <TableCell>
                      {editIndex === row.id ? (
                        <TextField
                          className="textField"
                          value={tempData?.git_url || ""}
                          onChange={(e) => handleFieldChange(e, "git_url")}
                        />
                      ) : (
                        <a
                          className="tableCell"
                          href={row.git_url || "#"}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {row.git_url || ""}
                        </a>
                      )}
                    </TableCell>
                    <TableCell>
                      {editIndex === row.id ? (
                        <TextField
                          className="textField"
                          value={tempData?.host_url || ""}
                          onChange={(e) => handleFieldChange(e, "host_url")}
                        />
                      ) : (
                        <a
                          className="tableCell"
                          href={row.host_url || "#"}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {row.host_url || ""}
                        </a>
                      )}
                    </TableCell>

                    {/* <TableCell>
                      {editIndex === row.figma.id ? (
                        <TextField
                          className="textField"
                          value={tempData?.screen_name || ""}
                          onChange={(e) => handleFieldChange(e, "screen_name")}
                        />
                      ) : (
                        row.screen_name || "-"
                      )}
                    </TableCell> */}
                    {/* <TableCell>
                      {row.status.id === 3 ? (
                        <div className="empty-dash">-</div>
                      ) : (
                        <Button
                          className="muiButton"
                          variant="contained"
                          color="secondary"
                          onClick={() => handleGenerate(row)}
                        >
                          {getButtonValue(row)}
                        </Button>
                      )}
                    </TableCell> */}
                    <TableCell>
                      {editIndex === row.id ? (
                        <FormControl className="select-dropdown" fullWidth>
                          <InputLabel className="tableCell"> Status</InputLabel>
                          <Select
                            value={tempData?.status.id.toString()}
                            onChange={handleStatusChange}
                            label="Status"
                          >
                            {statusList.map((status) => (
                              <MenuItem
                                className="tableCell"
                                key={status.id}
                                value={status.id}
                              >
                                {status.status}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      ) : (
                        <p className="tableCell">{row.status.status}</p>
                      )}
                    </TableCell>
                    <TableCell>
                      <p className="tableCell"> {getButtonLabel(row)}</p>
                    </TableCell>

                    <TableCell>
                      {editIndex === row.id ? (
                        <div className="buttons">
                          <Tooltip title="Save">
                            <IconButton>
                              <CheckIcon
                                sx={{
                                  cursor: "pointer",
                                  color: "green",
                                  border: "2px solid green",
                                  borderRadius: "50%",
                                }}
                                onClick={() => handleSaveClick(row.id)}
                              />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Cancel">
                            <IconButton>
                              <ClearIcon
                                sx={{
                                  cursor: "pointer",
                                  color: "red",
                                  border: "2px solid red",
                                  borderRadius: "50%",
                                }}
                                onClick={handleCancelClick}
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      ) : (
                        <Button
                          variant="contained"
                          className="muiButton"
                          onClick={() => handleEditClick(row.id)}
                        >
                          Edit
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
      />
      <Snackbar
        open={errors !== ""}
        autoHideDuration={6000}
        onClose={() => setErrors("")}
        message={errors}
      />
    </div>
  );
};

export default TlListing;
