import React, { useEffect, useState } from "react";
import {
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Snackbar,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material";
import "./registration.css";
import useFetch from "../../services/apiService";
import usePost from "../../services/apiPostService";
import {
  COMPANY,
  CREATE,
  CREATE_USER,
  FULL_NAME,
  MESSAGE,
  NO,
  NONE,
  NO_DATA_AVAILABLE,
  PO_ACCESSED,
  TL_ACCESSED,
  WORK_EMAIL,
  YES,
} from "../../constants";

interface RegistrationData {
  user_id: number;
  first_name: string;
  email: string;
  company: string;
  message: string;
  is_created: number;
  tl_status: number;
  po_status: number;
}

const RegistrationComponent: React.FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [registrationData, setRegistrationData] = useState<RegistrationData[]>(
    [],
  );
  const [errors, setErrors] = useState<string>("");
  const [createClicked, setCreateClicked] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [poAccessed, setPoAccessed] = useState<number | null>(null);
  const [tlAccessed, setTlAccessed] = useState<number | null>(null);
  const [searchText, setSearchText] = useState<string | null>(null);
  const [loadingCreate, setLoadingCreate] = useState<number | null>(null);
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setCurrentPage(value);
  };

  const buildUrl = () => {
    let url = "aura/registration/";

    const params = new URLSearchParams();

    if (currentPage > 0) params.append("page", currentPage.toString());
    if (tlAccessed !== null) params.append("tl_status", tlAccessed.toString());
    if (poAccessed !== null) params.append("po_status", poAccessed.toString());
    if (searchText != null) params.append("search_text", searchText.toString());
    const queryString = params.toString();

    if (queryString) {
      url += `?${queryString}`;
    }

    return url;
  };

  const { doFetch, data, loading } = useFetch();

  const iFetch = async () => {
    const url = buildUrl();
    await doFetch(url);
  };
  useEffect(() => {
    if (data) {
      // console.log(data?.data.data.data);
      setRegistrationData(data?.data.data.data);
      setTotalPages(data?.data.data.page.total_pages);
      setCurrentPage(data?.data.data.page.number);
    }
  }, [data]);

  const handlePoAccessedChange = (event: SelectChangeEvent<"yes" | "no">) => {
    setPoAccessed(
      event.target.value === "yes" ? 1 : event.target.value === "no" ? 0 : null,
    );
    setCurrentPage(1);
  };

  const handleTlAccessedChange = (event: SelectChangeEvent<"yes" | "no">) => {
    setTlAccessed(
      event.target.value === "yes" ? 1 : event.target.value === "no" ? 0 : null,
    );
    setCurrentPage(1);
  };
  const { doPost, error } = usePost();
  const handleCreateUser = async (user: number) => {
    setLoadingCreate(user);

    await doPost("aura/person/create", {
      user_id: user,
    });
    setLoadingCreate(null);
    setCreateClicked(!createClicked);
  };
  if (error) {
    setErrors("Something Went Wrong");
  }
  useEffect(() => {
    iFetch();
  }, [currentPage, poAccessed, tlAccessed, searchText, createClicked, errors]);
  return (
    <div className="admin-tabs-table-container">
      <TextField
        id="outlined-basic"
        onChange={handleSearch}
        label="Search"
        variant="outlined"
        className="searchField"
      />

      {loading ? (
        <div className="loadingContainer">
          <LinearProgress />
        </div>
      ) : (
        <TableContainer
          sx={{ height: "65vh", overflowY: "scroll" }}
          component={Paper}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="tableHead">{FULL_NAME}</TableCell>
                <TableCell className="tableHead">{WORK_EMAIL}</TableCell>
                <TableCell className="tableHead">{COMPANY}</TableCell>
                <TableCell className="tableHead">{MESSAGE}</TableCell>
                <TableCell className="tableHead">
                  <FormControl fullWidth className="form-field">
                    <InputLabel>{PO_ACCESSED}</InputLabel>
                    <Select
                      value={
                        poAccessed === 1 ? "yes" : poAccessed === 0 ? "no" : ""
                      }
                      onChange={handlePoAccessedChange}
                      className="header-select"
                      label="PO Accessed"
                    >
                      <MenuItem className="tableCell" value="">
                        {NONE}
                      </MenuItem>
                      <MenuItem className="tableCell" value="yes">
                        {YES}
                      </MenuItem>
                      <MenuItem className="tableCell" value="no">
                        {NO}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell className="tableHead">
                  <FormControl fullWidth className="form-field">
                    <InputLabel>{TL_ACCESSED}</InputLabel>
                    <Select
                      value={
                        tlAccessed === 1 ? "yes" : tlAccessed === 0 ? "no" : ""
                      }
                      onChange={handleTlAccessedChange}
                      className="header-select"
                      label="TL Accessed"
                    >
                      <MenuItem className="tableCell" value="">
                        {NONE}
                      </MenuItem>
                      <MenuItem className="tableCell" value="yes">
                        {YES}
                      </MenuItem>
                      <MenuItem className="tableCell" value="no">
                        {NO}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell className="tableHead">{CREATE_USER}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {registrationData.length > 0 ? (
                registrationData.map((row: RegistrationData, index: number) => (
                  <TableRow key={index}>
                    <TableCell className="tableCell">
                      {row.first_name}
                    </TableCell>
                    <TableCell className="tableCell">
                      {row.email || "-"}
                    </TableCell>
                    <TableCell className="tableCell">
                      {row.company || "-"}
                    </TableCell>
                    <TableCell className="tableCell">
                      {row.message || "-"}
                    </TableCell>
                    <TableCell className="tableCell">
                      {row.po_status ? "Yes" : "No"}
                    </TableCell>
                    <TableCell className="tableCell">
                      {" "}
                      {row.tl_status ? "Yes" : "No"}
                    </TableCell>
                    {/* <TableCell>
                      {row.is_created ? (
                        <span>-</span>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          className="createUser"
                          onClick={() => handleCreateUser(row.user_id)}
                        >
                          {CREATE}
                        </Button>
                      )}
                    </TableCell> */}
                    <TableCell>
                      {row.is_created ? (
                        <span>-</span>
                      ) : loadingCreate === row.user_id ? (
                        <CircularProgress size={24} />
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          className="createUser"
                          onClick={() => handleCreateUser(row.user_id)}
                        >
                          {CREATE}
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell className="tableCell" colSpan={8} align="center">
                    {NO_DATA_AVAILABLE}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
      />
      <Snackbar
        open={errors !== ""}
        autoHideDuration={6000}
        onClose={() => setErrors("")}
      />
    </div>
  );
};

export default RegistrationComponent;
