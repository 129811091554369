import poHoverImage from "../assets/Images/poHoverImage.svg";
import assistantImage from "../assets/Images/assistantImage.svg";
// import dbAssistant from '../common/Assets/Images/dbAssistant.svg';
import feImage from "../assets/Images/feImage.svg";
import pmImage from "../assets/Images/pmImage.svg";
import qaAssistant from "../assets/Images/qaAssistant.svg";
// import uiAssistant from '../assets/Images/uiAssistant.svg';
// import uxAssistant from '../assets/Images/uxAssistant.svg';
import poAssistant from "../assets/Images/poAssistant.svg";
import feHoverImage from "../assets/Images/feHoverImage.svg";
export const roleCard = [
  {
    id: 1,
    text: "PO Assistant",
    activeImage: poAssistant,
    hoverImage: poHoverImage,
  },
  {
    id: 2,
    text: "Front End Engineering Assistant",
    activeImage: feImage,
    hoverImage: feHoverImage,
  },
  {
    id: 3,
    text: "Project Mgmt Assistant",
    activeImage: pmImage,
    hoverImage: poHoverImage,
  },
  {
    id: 4,
    text: "System Architect Assistant",
    activeImage: assistantImage,
    hoverImage: poHoverImage,
  },
  {
    id: 5,
    text: "UX Assistant",
    activeImage: poAssistant,
    hoverImage: poHoverImage,
  },
  {
    id: 6,
    text: "UI Assistant",
    activeImage: poAssistant,
    hoverImage: poHoverImage,
  },
  {
    id: 7,
    text: "Backend DB  Assistant",
    activeImage: poAssistant,
    hoverImage: poHoverImage,
  },
  {
    id: 8,
    text: "Backend DB QA Automation Assistant",
    activeImage: qaAssistant,
    hoverImage: poHoverImage,
  },
];

export const guideLines = [
  "Pages: Limit your file to a single page with all screens included.",
  "Naming Conventions: Use clear, unique names for all layers and elements.",
  "Layer Structure: Organize layers with a clear hierarchy using frames, not groups.",
  "Auto Layout: Apply auto layout consistently across your design.",
  "Components: Use components for reusable elements and ensure all icons are components.",
  "Text Handling: Convert all text to outlines if required.",
];

export const behindTheScenesContent = [
  "Conduct thorough design analysis",
  "Group layers effectively for better organization",
  "Isolate assets for separate handling",
  "Establish a consistent design theme",
  "Identify UI components for reuse",
  "Isolate reusable components",
  "Prepare the design for code generation",
  "Generate HTML, CSS, and React components",
  "Invoke automation for deployment in a sandbox",
];
