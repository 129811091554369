import auraLogo from "../../assets/Images/aura-logo.svg";
import "./header.css";
import { useEffect, useState } from "react";

import { routeConfig } from "../../constants/routeConfig";
import { useLocation, useNavigate } from "react-router-dom";
import CustomModal from "../Modal/model";
// import CustomButton from "../../common/CustomButton";

const user = JSON.parse(sessionStorage.getItem('user') || '{}');
const isAdmin = user?.role?.name === 'admin';
const route = isAdmin ? routeConfig.admin : routeConfig.userDashboard;
const Header = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const handleConfirmLogout = () => {
    setIsModalOpen(false);
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("user");
    navigate(routeConfig.login);
  };
  const handleLogout = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (
      location.pathname === routeConfig.login ||
      location.pathname === routeConfig.register
    ) {
      sessionStorage.removeItem("access_token");
      sessionStorage.removeItem("user");
    }
  }, [location.pathname]);
  const token = sessionStorage.getItem("access_token");
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="header-container">
      <div
        className="auraLogoContainer"
        onClick={() => navigate(routeConfig.userDashboard)}
      >
       {token &&
      location.pathname != routeConfig.login &&
      location.pathname != routeConfig.register ? ( <img className="auraLogo" src={auraLogo} alt="Aura Logo" />):<></>}
        <p className="experienceAura">Aura</p>
      </div>
      {token &&
      location.pathname != routeConfig.login &&
      location.pathname != routeConfig.register ? (
        // <CustomButton
        //   text={"Logout"}
        //   onClick={handleLogout}
        //   className="logout-button"
        // />
        <div className="header-right-container">
          <a className="header-home" href={route}>
            Home
          </a>
          <div className="header-line-separator"></div>
          <div onClick={handleLogout} className="header-logout">
            Logout
          </div>
        </div>
      ) : (
        <></>
      )}
      <CustomModal
        open={isModalOpen}
        title="Confirm Logout..!!"
        onClose={handleCloseModal}
        onConfirm={handleConfirmLogout}
        confirmText="Logout"
        actionConfirmButtonClassName="confirmButtonStyle"
      >
        {"Are You Sure You Want To Logout?"}
      </CustomModal>
    </div>
  );
};

export default Header;
