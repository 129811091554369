import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TlListing from "../TlListing/tlListing";
import RegistrationComponent from "../RegistrationComponent/registrationComponent";
import PoListing from "../PoListing/poListing";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const tabContents = [
    {
      label: "Registration",
      content: <RegistrationComponent />,
    },
    { label: "Engineering Figma Listing", content: <TlListing /> },
    { label: "Po Listing", content: <PoListing /> },
  ];

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          position: "sticky",
          top: "68px",
          backgroundColor: "#ffffff",
          zIndex: 100,
        }}
      >
        <Tabs
          sx={{
            ".MuiTabs-flexContainer": {
              display: "flex",
              gap: "25px",
              borderBottom: "3px solid #E0E0E0",
              justifyContent: "space-between",
              "@media (max-width: 599px)": {
                gap: "0",
                justifyContent: "space-between",
              },
            },
          }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabContents.map((tab, index) => (
            <Tab key={index} label={tab.label} {...a11yProps(index)} />
          ))}
        </Tabs>
      </Box>
      {tabContents.map((tab, index) => (
        <CustomTabPanel key={index} value={value} index={index}>
          {tab.content}
        </CustomTabPanel>
      ))}
    </Box>
  );
}
