import React, { useEffect } from "react";
import "../Login/loginStyles.css";
import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import TextField from "../../common/Input/inputField";
import { useNavigate } from "react-router-dom";
import { routeConfig } from "../../constants/routeConfig";
import usePost from "../../services/apiPostService";
import { messages } from "../../constants/messages";
import { Button, Snackbar, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import arrow from "../../assets/Images/arrow.svg";

interface iLoginProps {
  email: string;
  password: string;
  onSubmit?: () => void;
}

const Login = () => {
  const navigate = useNavigate();
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{15}$/;
  
  const schema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required")
      .matches(emailRegex, "Invalid email"),
    password: Yup.string()
      .matches(passwordRegex)
      .required("Password is required"),
  });

  const {
    register,
    formState: { errors, isSubmitted },
    handleSubmit,
    setValue,
    watch,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onChangeField = React.useCallback(
    (name: "email" | "password") =>
      async (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(name, event.target.value);
        await trigger(name);
      },
    [setValue, isSubmitted, trigger],
  );

  const { totaldata, doPost,error } = usePost();
  const [errorMessage, setError] = React.useState<string | null>("");

  useEffect(() => {
    if (error) {
      setError(error?.response?.data?.message || "Something went wrong");
    }
  }, [error]);
  
  const onSubmit: SubmitHandler<iLoginProps> = async (data) => {
    const payload = {
      email: data.email,
      password: data.password,
    };
    try {
      await doPost("aura/auth/login", payload);
    } catch (e) {
      console.error("Login failed:", e);
      setError("Something went wrong");
    }
  };

  useEffect(() => {
    if (totaldata?.status === 200 && totaldata.data.status === "Success") {
      const role_id = totaldata.data.data.user.role_id;
      sessionStorage.setItem("access_token", totaldata.data.data.access_token);
      sessionStorage.setItem("user", JSON.stringify(totaldata.data.data.user));
      if (role_id === 1) {
        navigate(routeConfig.admin);
      } else if (role_id === 2) {
        navigate(routeConfig.userDashboard);
      }
    } else if (totaldata && totaldata.data.status !== "Success") {
      console.error("Login failed:", totaldata.data.message);
      setError(totaldata.data.message);
    }
  }, [totaldata, navigate]);

  const isFormValid =
    !errors.email && !errors.password && watch("email") && watch("password");

  const handleCloseSnackbar = () => {
    setError(null);
  };

  return (
    <div className="loginBackground">
      <div className="loginContainer">
        <div className="loginPageWrapper">
          <div className="textRole">{messages.SIGNIN}</div>
          <div className="textRoleDesc">{messages.SIGNINDESC}</div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="loginWrapper">
              <div className="inputFieldWrapperEmail">
                <TextField
                  type="text"
                  placeholder="Email"
                  {...register("email")}
                  onChange={onChangeField("email")}
                  value={watch("email")}
                  errorMessage={errors.email?.message}
                />
                {errors.email && (
                  <p className="errorText">{errors.email.message}</p>
                )}
              </div>
              <div className="inputFieldWrapperPassword">
                <TextField
                  type="password"
                  placeholder="Password"
                  {...register("password")}
                  onChange={onChangeField("password")}
                  value={watch("password")}
                />
                {errors.password && (
                  <p className="errorText"></p>
                )}
              </div>
              <Button
                className="loginButton"
                type="submit"
                disabled={!isFormValid}
                style={{
                  width: "131px",
                  height: "56px",
                  borderRadius: "999px",
                  fontSize: "16px",
                  fontWeight: "500",
                  textAlign: "center",
                  cursor: isFormValid ? "pointer" : "not-allowed",
                  backgroundColor: isFormValid ? "red" : "#E0E3E6",
                  color: "#fff",
                  border: "none",
                  transition: "background-color 0.3s ease",
                }}
              >
                {messages.SIGNIN}
                <img
                  src={arrow}
                  alt="arrow"
                  className="arrow"
                  style={{ marginLeft: "4px", height: "16px", width: "16px" }}
                />
              </Button>
            </div>
          </form>
          <div className="contactSupport">
            {messages.NEWUSER} <a href="/register">{messages.REGISTER}</a>
          </div>
        </div>
      </div>
      <Snackbar
        open={!!error}
        message={errorMessage}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackbar}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        className="custom-snackbar"
      />
    </div>
  );
};

export default Login;
