import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Pagination,
  Button,
  Snackbar,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import "./polisting.css";
import useFetch from "../../services/apiService";
import usePut from "../../services/apiPutService";
import {
  ACTION,
  COMPANY,
  DELIVER,
  DOCUMENT_URL,
  EMAIL,
  FINAL_DELIVERED_SPEC,
  FIRST_NAME,
  LAST_NAME,
  STATUS,
  TITLE,
  UPLOAD_FILE,
} from "../../constants";
import usePost from "../../services/apiPostService";
interface User {
  user_id: number;
  first_name: string;
  last_name: string;
  email: string;
  company: string | null;
}
interface Status {
  status_id: number;
  status_name: string;
}
interface ChatData {
  chat_id: number;
  title: string | null;
  status: Status;
  user: User;
  spec_url: string | null;
  doc_url: string;
}

const PoListing: React.FC = () => {
  const [poListingData, setPoListingData] = useState<ChatData[]>([]);
  const [totalPages, setTotalPages] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>("");
  const [errors, setErrors] = useState<string>("");
  const [action, setAction] = useState<boolean>(false);
  const [loadingDeliver, setLoadingDeliver] = useState<number | null>(null);
  const [uploadedFileNames, setUploadedFileNames] = useState<
    Record<number, string>
  >({});

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setCurrentPage(value);
  };
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };
  const { doPut, error } = usePut();
  const handleAction = async (id: number) => {
    console.log("id", id);
    setLoadingDeliver(id);

    try {
      await doPut(`aura/po/chat/${id}/status`, {
        status_id: 5,
      });
    } catch (error) {
      console.error("Error updating status:", error);
    } finally {
      setLoadingDeliver(null);
      setAction(!action);
    }
  };

  const { doFetch, data, loading } = useFetch();
  const buildUrl = () => {
    let url = "aura/po/chat/";
    const params = new URLSearchParams();
    if (currentPage > 0) params.append("page", currentPage.toString());
    if (searchText.trim() !== "")
      params.append("search_text", searchText.toString());

    const queryString = params.toString();
    if (queryString) {
      url += `?${queryString}`;
    }

    return url;
  };
  const iFetch = async () => {
    const url = buildUrl();
    await doFetch(url);
  };
  useEffect(() => {
    iFetch();
  }, [searchText, currentPage, action, uploadedFileNames]);

  useEffect(() => {
    if (data) {
    
      setTotalPages(data.data.data.page.total_pages);
      setPoListingData(data.data.data.data);
    }
  }, [data]);

  if (error) {
    setErrors("Something went wrong");
  }
  const { doPost, totaldata } = usePost();
  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    chatId: number,
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      await doPost(
        `aura/upload/${chatId}/spec`,
        {
          file: file,
        },
        "multipart/form-data",
      );

      console.log("File uploaded:", file.name);
      setUploadedFileNames((prev) => ({ ...prev, [chatId]: file.name }));
    }
  };
  if (totaldata) {
    console.log("totaldata", totaldata);
    const chatId = totaldata.data.data.chat_id;
    const fileName = totaldata.data.data.filename;
    console.log("chatId", chatId, "file", fileName);
    // setUploadedFileNames((prev) => ({ ...prev, [chatId]: file.name }));
  }

  return (
    <div className="admin-tabs-table-container">
      <TextField
        id="outlined-basic"
        onChange={handleSearch}
        label="Search"
        variant="outlined"
        className="searchField"
      />
      {loading ? (
        <div className="loadingContainer">
          <LinearProgress />
        </div>
      ) : (
        <TableContainer
          sx={{ height: "65vh", overflowY: "scroll" }}
          component={Paper}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="tableHead">{TITLE}</TableCell>

                <TableCell className="tableHead">{FIRST_NAME}</TableCell>
                <TableCell className="tableHead">{LAST_NAME}</TableCell>
                <TableCell className="tableHead">{EMAIL}</TableCell>
                <TableCell className="tableHead">{COMPANY}</TableCell>
                <TableCell className="tableHead">{STATUS}</TableCell>

                <TableCell className="tableHead">{DOCUMENT_URL}</TableCell>
                <TableCell className="tableHead">{UPLOAD_FILE}</TableCell>
                <TableCell className="tableHead">
                  {FINAL_DELIVERED_SPEC}
                </TableCell>
                <TableCell className="tableHead">{ACTION}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {poListingData.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8} style={{ textAlign: "center" }}>
                    No data available
                  </TableCell>
                </TableRow>
              ) : (
                poListingData.map((row) => (
                  <TableRow key={row.chat_id}>
                    <TableCell className="tableCell">
                      {row.title ?? "N/A"}
                    </TableCell>

                    <TableCell className="tableCell">
                      {row.user.first_name}
                    </TableCell>
                    <TableCell className="tableCell">
                      {row.user.last_name}
                    </TableCell>
                    <TableCell className="tableCell">
                      {row.user.email}
                    </TableCell>
                    <TableCell className="tableCell">
                      {row.user.company ?? "N/A"}
                    </TableCell>
                    <TableCell className="tableCell">
                      {row.status?.status_name ?? "N/A"}
                    </TableCell>
                    <TableCell className="tableCell">
                      {row.doc_url ? (
                        <a href={row.doc_url}>{"Download"}</a>
                      ) : (
                        "N/A"
                      )}
                    </TableCell>
                    <TableCell>
                      {row.status?.status_id === 4 ? (
                        <div>
                          <input
                            type="file"
                            accept=".pdf,.docx, .doc, .txt"
                            className="fileInput"
                            onChange={(e) => handleFileUpload(e, row.chat_id)}
                            style={{ display: "none" }}
                            id={`upload-file-${row.chat_id}`}
                          />
                          <label htmlFor={`upload-file-${row.chat_id}`}>
                            <Button
                              className="muiButton"
                              variant="contained"
                              component="span"
                            >
                              {UPLOAD_FILE}
                            </Button>
                          </label>
                        </div>
                      ) : (
                        ""
                      )}

                      {uploadedFileNames[row.chat_id] && (
                        <p className="fileName">
                          {uploadedFileNames[row.chat_id]}
                        </p>
                      )}
                    </TableCell>
                    <TableCell className="tableCell">
                      {row.spec_url ? (
                        <a href={row.spec_url}>{"Download"}</a>
                      ) : (
                        "N/A"
                      )}
                    </TableCell>
                    <TableCell>
                      {row.status?.status_id === 6 ? (
                        loadingDeliver == row.chat_id ? (
                          <CircularProgress size={24} />
                        ) : (
                          <Button
                            className="muiButton"
                            variant="contained"
                            onClick={() => handleAction(row.chat_id)}
                          >
                            {DELIVER}
                          </Button>
                        )
                      ) : (
                        <p>-</p>
                      )}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handlePageChange}
      />
      <Snackbar
        open={errors !== ""}
        autoHideDuration={6000}
        onClose={() => setErrors("")}
        message={errors}
      />
    </div>
  );
};

export default PoListing;
